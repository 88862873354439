import React from "react";

interface ForgeViewerPropType extends React.HTMLProps<HTMLDivElement> {
  urn: string[];
  glTF: string[];
  viewer: Autodesk.Viewing.GuiViewer3D | null;
  viewerRenderer(
    ref: React.RefObject<HTMLDivElement>,
    urn: string[],
    glTF?: string[]
  ): void;
}

export function ForgeViewer(props: ForgeViewerPropType) {
  const { urn, glTF, viewer, viewerRenderer, ...rest } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (viewer) {
      viewer.tearDown();
    }
    viewerRenderer(ref, urn, glTF);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, viewerRenderer, urn]);
  return (
    <div
      ref={ref}
      style={{ height: "100vh", width: "100vw" }}
      id="forgeViewer"
      {...rest}
    ></div>
  );
}
