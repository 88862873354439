import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { forgeViewerInitializeThunk } from './thunks';
export * from './thunks';

interface State {
  loading: boolean;
  accessToken: string;
  tokenType: string;
  expiresIn: number;
  error: boolean;
  message: string;
  doc: (Autodesk.Viewing.Document | null)[];
  viewer: Autodesk.Viewing.GuiViewer3D | null;
  urn: string[];
  gltf: string[];
}

const initialState: State = {
  loading: false,
  accessToken: '',
  tokenType: '',
  expiresIn: 0,
  error: false,
  message: '',
  doc: [],
  viewer: null,
  // urn: [],
  urn: [
    'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi8teG1ja2hQOEctJUUxJTg0JThCJUUxJTg1JUE3JUUxJTg2JUJDJUUxJTg0JThFJUUxJTg1JUE1JUUxJTg2JUFCJUUxJTg0JTgzJUUxJTg1JUE5JUUxJTg2JUJDNzAtOSglRTElODQlODYlRTElODUlQTElRTElODQlODAlRTElODUlQTElRTElODYlQjcpLm53Yw',
    //   'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LXByb2QvMTg2NzMzNmEtMGRkMy00MDBkLTg2NTAtMDQxMGViNmI3NTk4Lm53Yw',
  ],
  // urn: [
  //   "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi9yZWJhci1za3Rqel9GSDcubndj",
  //   "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi9mb3Jtd29yay1aSm9EWXRjSXcubndj",
  //   "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi9CQVNFLUh6NHprMk94Si5ud2M",
  // ],
  // gltf: ['project/03/BH-A_FIN.glTF.gltf', 'project/03/BH-Q_MASTER.glTF.gltf'],
  // gltf: ['project/03/BH-Q_MASTER.glTF.gltf'],
  gltf: [],
};

export const forgeSlice = createSlice({
  name: 'autudeskForge',
  initialState,
  reducers: {
    setUrn3D(state, action: PayloadAction<string[]>) {
      state.urn = action.payload;
    },
    setGlTF(state, action: PayloadAction<string[]>) {
      state.gltf = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(forgeViewerInitializeThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgeViewerInitializeThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.accessToken = action.payload.tokenData.access_token;
        state.tokenType = action.payload.tokenData.token_type;
        state.expiresIn = action.payload.tokenData.expires_in;
        state.doc = action.payload.doc;
        // @ts-ignore
        state.viewer = action.payload.viewer;
      })
      .addCase(forgeViewerInitializeThunk.rejected, (state, response) => {
        state.loading = false;
        state.error = true;
        state.message = response.error.message || '';
      });
  },
});

export const { setUrn3D } = forgeSlice.actions;
