import { createAsyncThunk } from '@reduxjs/toolkit';
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
// import * as THREE from "three";

const uri = 'https://api.demo.platform.builderhub.io/forge_token';

async function api<T>(url: string): Promise<T> {
  return fetch(url).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  });
}
export const forgeViewerInitializeThunk = createAsyncThunk('forge/viewerInit', forgeInitialize);

type ForgeTokenPayload = {
  access_token: string;
  token_type: 'Bearer';
  expires_in: number;
  expires_at: number;
};

/**
 * It gets the current user's session, gets the JWT token from the session, creates a GraphQL client
 * with the JWT token as the authorization header, gets the Forge SDK from the client, and then gets
 * the Forge token from the SDK
 * @returns The token is being returned.
 */
async function getForgeTokenData() {
  const token = await api<ForgeTokenPayload>(uri);
  return token;
}

interface ForgeInitializerParams {
  el: HTMLDivElement;
  urn: string[];
  glTF?: string[];
}

interface ForgeIntializerResolvedValue {
  /**
   * The Autodesk.Viewing.Document loaded document
   */
  doc: (Autodesk.Viewing.Document | null)[];
  /**
   * The Forge token data
   */
  tokenData: {
    __typename?: 'ForgeToken' | undefined;
    access_token: string;
    token_type: string;
    expires_in: number;
  };
  /**
   * Forge Viewer
   */
  viewer: Autodesk.Viewing.GuiViewer3D;
}

/**
 * It initializes the Forge viewer, loads the model, and returns the viewer, the model, and the Forge
 * token data
 * @param {ForgeInitializerParams}  - `el` - the DOM element where the viewer will be rendered
 * @returns An object with the following properties:
 * - doc: The loaded document
 * - tokenData: The token data
 * - viewer: The viewer
 */
async function forgeInitialize({ el, urn, glTF }: ForgeInitializerParams): Promise<ForgeIntializerResolvedValue> {
  const tokenData = await getForgeTokenData();
  const options: Autodesk.Viewing.InitializerOptions = {
    env: 'AutodeskProduction',
    api: 'derivativeV2',
    getAccessToken(callback?) {
      getForgeTokenData().then((token) => {
        const { access_token, expires_in } = token;
        if (callback) {
          callback(access_token, expires_in);
        }
      });
    },
  };
  const viewer = await forgeViewerInit(options, el);
  await viewer.start();
  Autodesk.Viewing.Private.LightPresets.push({
    name: 'Custom',
    tonemap: 2,
    E_bias: 0,
    path: null,
    directLightColor: [0, 0.84, 0.67],
    // ambientColor: [0.8, 0.9, 1],
    lightMultiplier: 0.1,
    bgColorGradient: [250, 250, 252, 250, 250, 252],
    darkerFade: false,
    lightDirection: [-20, -20, -20],
  });
  viewer.setLightPreset(Autodesk.Viewing.Private.LightPresets.length - 1);
  // viewer.restoreDefaultSettings();
  // viewer.setLightPreset(4);
  // @ts-ignore
  // viewer.impl.setShadowLightDirection(new THREE.Vector3(-1, 2, 1));
  // viewer.setBackgroundColor(0, 0, 0, 0, 0, 0);
  // @ts-ignore
  // viewer.impl.renderer().setAOOptions(10, 0.1);
  // viewer.navigation.toPerspective();
  // viewer.setFOV(25);
  const doc = [];
  if (urn.length > 0) doc.push(...(await Promise.all(urn.map((file) => forgeViewerLoad(file)))));
  if (glTF && glTF.length > 0) doc.push(...(await Promise.all(glTF.map((file) => forgeViewerGlTFLoad(viewer, file)))));
  if (doc.length > 0) {
    doc.forEach((model) => {
      if (!model) return;
      const defaultModel = model.getRoot().getDefaultGeometry();
      viewer.loadDocumentNode(model, defaultModel);
    });
  }
  await viewer.waitForLoadDone({ geometry: true, textures: true });
  viewer.setQualityLevel(true, false);
  viewer.setGhosting(false);
  viewer.setEnvMapBackground(false);
  viewer.setGroundShadow(true);
  // viewer.setBackgroundColor(250, 250, 252, 250, 250, 252);
  // @ts-ignore
  const listOfMaterials = viewer.impl.matman()._materials;
  Object.keys(listOfMaterials).map((materialName) => {
    // logger.log({ material: listOfMaterials[materialName] });
    if (listOfMaterials[materialName].type === 'MeshPhongMaterial') {
      listOfMaterials[materialName].reflectivity = 0;
      listOfMaterials[materialName].refractionRatio = 0;
      listOfMaterials[materialName].shading = 0;
      listOfMaterials[materialName].fog = false;
      listOfMaterials[materialName].shininess = 15;
    }
  });
  const parentCam = viewer.impl.camera;
  const amb_light = new THREE.AmbientLight(0xffffff, 1);
  amb_light.parent = parentCam;
  const dir_light_top = new THREE.DirectionalLight(0x00ff00, 1);
  dir_light_top.position.set(0, 1, 0);
  dir_light_top.parent = parentCam;
  // parentCam.children = [dir_light_top, amb_light];
  // // @ts-ignore
  // dir_light_top.shadowCamera = new THREE.OrthographicCamera(-500, 500, 500, -500, 0.1, 5000);
  // @ts-ignore
  viewer.impl.lights = [dir_light_top, amb_light];
  parentCam.children = [dir_light_top, amb_light];
  // @ts-ignore
  console.log({ lights: viewer.impl.lights });
  // viewer.showAll();
  viewer.fitToView();
  return { doc, tokenData, viewer };
}

/**
 * It returns a promise that resolves to a viewer object
 * @param options - Autodesk.Viewing.InitializerOptions
 * @param {HTMLDivElement} el - HTMLDivElement - the div element that will contain the viewer
 * @param {string} urn - The URN of the model you want to load.
 * @returns A promise that resolves to a viewer object.
 */
async function forgeViewerInit(
  options: Autodesk.Viewing.InitializerOptions,
  el: HTMLDivElement,
): Promise<Autodesk.Viewing.GuiViewer3D> {
  return new Promise((resolve, reject) => {
    Autodesk.Viewing.Initializer(options, async function onInitialized() {
      const viewer = new Autodesk.Viewing.GuiViewer3D(el);
      await viewer.loadExtension('Autodesk.Viewing.Popout');
      await viewer.loadExtension('Autodesk.ModelStructure');
      await viewer.loadExtension('Autodesk.Fusion360.Animation');
      const extensions = Autodesk.Viewing.theExtensionManager.getRegisteredExtensions();
      console.log({ extensions });
      resolve(viewer);
    });
  });
}

/**
 * It loads a Forge model from a URN and returns a promise that resolves to a Forge document
 * @param {string} urn - The URN of the model you want to load.
 * @returns A promise that resolves to a document object.
 */
async function forgeViewerLoad(urn: string): Promise<Autodesk.Viewing.Document> {
  return new Promise((resolve, reject) => {
    Autodesk.Viewing.Document.load(
      urn,
      function onDocumentLoadSuccess(doc) {
        resolve(doc);
      },
      function onDocumentLoadFailure(error) {
        console.log({ error });
        reject(error);
      },
    );
  });
}

async function forgeViewerGlTFLoad(
  viewer: Autodesk.Viewing.GuiViewer3D,
  glTF: string,
): Promise<Autodesk.Viewing.Document | null> {
  return new Promise((resolve, reject) => {
    viewer
      .loadExtension('Autodesk.glTF')
      .then(() => {
        viewer.loadModel(glTF, { glTF });
        resolve(null);
      })
      .catch(reject);
  });
}
