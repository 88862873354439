import React from "react";
import "./App.css";
import { ForgeViewer } from "components";
import { useAppSelector } from "store";
import { useForgeViewer } from "hooks";

function App() {
  const { viewer, viewerRenderer } = useForgeViewer();
  const { urn, gltf } = useAppSelector((state) => state.forge);
  return (
    <div className="App">
      {urn ? (
        <ForgeViewer
          urn={urn}
          glTF={gltf}
          viewer={viewer}
          viewerRenderer={viewerRenderer}
          style={{
            // position: "relative",
            // top: 0,
            // width: "100%",
            // height: "100%",
            zIndex: 1,
          }}
        />
      ) : null}
    </div>
  );
}

export default App;
