import React from "react";
import {
  useAppDispatch,
  forgeViewerInitializeThunk,
  useAppSelector,
} from "store";

export function useForgeViewer() {
  const { viewer } = useAppSelector((state) => state.forge);
  const dispatch = useAppDispatch();
  const viewerRenderer = React.useCallback(
    (ref: React.RefObject<HTMLDivElement>, urn: string[], glTF?: string[]) => {
      const el = ref.current;
      if (el) {
          dispatch(forgeViewerInitializeThunk({ el, urn, glTF }));
      }
    },
    [dispatch]
  );
  return { viewer, viewerRenderer };
}
